.swiper {
  width: 100%;
  height: 403px;
}

.swiper,
.swiper-container {
  overflow: visible !important;
}

.swiper-slide {
  background-position: center;
}

.swiper-slide img {
  width: 100%;
  height: 100%;
  display: block;
  cursor: grab;
  object-fit: cover;
}

.swiper-pagination-bullet {
  width: 13px;
  height: 13px;
  border-radius: 0;
  background-color: #000;
}
.swiper-pagination-bullets {
  bottom: -30px !important;
  gap: 10px;
  display: flex;
  justify-content: center;
}

.swiper-button-prev,
.swiper-button-next {
  background-color: rgba(128, 128, 128, 0.575);
  width: 42px;
  height: 42px;
  border-radius: 100%;
  transition: 0.4s all ease;
}
.swiper-button-prev::after,
.swiper-button-next::after {
  content: "";
  display: block;
  width: 50px;
  height: 50px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.swiper-button-next::after {
  background-image: url("../img/next_arrow_slider.svg");
  margin-left: 3px;
}
.swiper-button-prev::after {
  background-image: url("../img/prev_arrow_slider.svg");
  margin-right: 3px;
}
.swiper-button-prev:hover,
.swiper-button-next:hover {
  background-color: #fff;
}

@media (max-width: 600px) {
  .swiper-pagination-bullets {
    gap: 1px !important;
  }
  .swiper-pagination-bullets {
    width: 10px;
    height: 10px;
  }
}
@media (max-width: 400px) {
  .swiper-pagination-bullets {
    gap: 1px !important;
  }
  .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    gap: 1px !important;
  }
}
