.advances {
  width: 100%;
  padding: 3rem 10px;
}
.fix_kinds,
.diagnostic {
  padding: 0 10px;
}
.advances__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.advances__title {
  font-size: 2rem;
  text-align: center;
  position: relative;
}
.advances__title::after {
  content: "";
  width: 200px;
  height: 10px;
  background-color: #39b44a;
  position: absolute;
  bottom: -25px;
  left: 35%;
}

.advances__blocks_container {
  display: flex;
  max-width: 1200px;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  padding-top: 15px;
  justify-content: center;
}
.advances__block {
  width: 266px;
  height: 282px;
  font-weight: 400;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
}
.advances__block:hover {
  box-shadow: 12px 0 15px -10px gray, -12px 0 8px -10px gray;
}
.advances_block__title {
  font-weight: 400;
  font-size: 1.2rem;
}
.advances_block__text {
  font-size: 0.95rem;
}
.advances__man_icon {
  background-image: url("../img/advances_man.svg");
}
.advances__time_icon {
  background-image: url("../img/advances_time.svg");
}
.advances__secure_icon {
  background-image: url("../img/advances_secure.svg");
}
.advances__repair_icon {
  background-image: url("../img/advances_repair.svg");
}
.advances__man_icon,
.advances__repair_icon,
.advances__secure_icon,
.advances__time_icon {
  display: block;
  width: 80px;
  height: 80px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.advances__text_container {
  height: 136px;
  margin-bottom: 15px;
}
.advances__block:nth-child(1) {
  border-bottom: 12px solid gray;
}
.advances__block:nth-child(2) {
  border-bottom: 12px solid orangered;
}
.advances__block:nth-child(3) {
  border-bottom: 12px solid greenyellow;
}
.advances__block:nth-child(4) {
  border-bottom: 12px solid blue;
}

/* /fix_kinds */

.fix_kinds {
  max-width: 1100px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-bottom: 3rem;
}
.fix_kinds .advances__title::after {
  left: 40.5%;
}
.fix__blocks_container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-top: 2rem;
  flex-wrap: wrap;
  gap: 20px;
}
.fix__block {
  width: 325px;
  height: 162px;
  display: flex;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color: #fff;
  position: relative;
  justify-content: center;
  align-items: center;
  margin: 0px auto 30px;
  text-align: center;
}
.fix__block::after {
  content: "";
  position: absolute;
  background-color: rgba(73, 73, 73, 0.74);
  width: 100%;
  height: 100%;
  transition: 0.5s all ease;
}
.fix__block:hover::after {
  background-color: rgba(0, 0, 0, 0.678);
}
.fix__block img {
  width: 100%;
  height: 100%;
}
.fix__block_text {
  z-index: 1;
  border: 3px solid #fff;
  padding: 5px 10px;
  font-size: 1.5rem;
}
.fix__blocks_container .fix__block:nth-child(1) {
  background-image: url("../img/autodiagnostic.jpeg");
}
.fix__blocks_container .fix__block:nth-child(2) {
  background-image: url("../img/hodova.jpg");
}
.fix__blocks_container .fix__block:nth-child(3) {
  background-image: url("../img/other_services.jpg");
}
.fix__blocks_container .fix__block:nth-child(4) {
  background-image: url("../img/condicioner_fix.jpeg");
}
.fix__blocks_container .fix__block:nth-child(5) {
  background-image: url("../img/tire.jpg");
}
.fix__blocks_container .fix__block:nth-child(6) {
  background-image: url("../img/3d_rozval_fix.jpeg");
}
.fix__blocks_container .fix__block:nth-child(7) {
  background-image: url("../img/engine_fix.jpeg");
}
.fix__blocks_container .fix__block:nth-child(8) {
  background-image: url("../img/fluids_imgs.jpg");
}
.fix__blocks_container .fix__block:nth-child(9) {
  background-image: url("../img/break_imgs.jpg");
}
.fix__blocks_container .fix__block:nth-child(10) {
  background-image: url("../img/steering_imgs.jpg");
}
.fix__blocks_container .fix__block:nth-child(11) {
  background-image: url("../img/belt_imgs.jpg");
}
.fix__blocks_container .fix__block:nth-child(12) {
  background-image: url("../img/cooling_imgs.jpg");
}
.fix__blocks_container .fix__block:nth-child(13) {
  background-image: url("../img/clutch_imgs.webp");
}
.fix__blocks_container .fix__block:nth-child(14) {
  background-image: url("../img/injection_imgs.jpg");
}
.fix__blocks_container .fix__block:nth-child(15) {
  background-image: url("../img/engines_imgs.jpg");
}
.fix__blocks_container .fix__block:nth-child(16) {
  background-image: url("../img/radiator_imgs.jpg");
}
.fix__blocks_container .fix__block:nth-child(17) {
  background-image: url("../img/svarka_imgs.jpg");
}

/* //////diagnostic */

.diagnostic {
  max-width: 1172px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  font-size: 1rem;
}
.diagnostic .advances__title {
  margin-bottom: 3rem;
}
.diagnostic .advances__title::after {
  left: 42%;
}

/* ///bottom_slider */

.bottom_slider {
  display: flex;
  justify-content: center;
  margin: 3rem auto 2rem;
  text-align: center;
}

/* /////ABOUT */

.about {
  width: 100%;
  margin: 3rem 0;
}
.about__title {
  max-width: 1200px;
  margin: auto;
  margin-bottom: 40px;
  position: relative;
  font-size: 2.4rem;
}
.about__title::after {
  content: "";
  position: absolute;
  height: 10px;
  width: 200px;
  background-color: #39b44a;
  bottom: -20px;
  left: 0;
}
.about ul,
.service__block ul {
  list-style: initial;
  padding-left: 50px;
}
.about ul li,
.service__block ul li {
  color: #000;
}
.about__content {
  max-width: 1200px;
  margin: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.about__left_side {
  max-width: 800px;
}
.about__content h2 {
  margin: 0 0 20px 0;
  font-size: 1.75rem;
}
.about__right_side {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.about__content .about__right_side img:nth-child(1) {
  width: 350px;
}
.about__content .about__right_side img:nth-child(2) {
  width: 200px;
}

/* ////service */
.unActive {
  display: none !important;
}
.active {
  display: flex;
}
.service__block {
  width: 100%;
  padding-bottom: 3rem;
}
.service__block ul {
  margin: 15px 0;
  gap: 5px;
  display: flex;
  flex-direction: column;
}
.service__content {
  display: flex;
  flex-direction: column;
}
.autodiagnostic__banner,
.hodovaya__banner,
.rozval__banner,
.conditioner__banner,
.radiator__banner,
.replacement_belts__banner,
.clutch__banner,
.cooling_system__banner,
.injection__banner,
.ignition__banner,
.steering__banner,
.electric__banner,
.tehfluids__banner,
.braksystem__banner,
.tire__banner,
.engine__banner,
.svarka__banner,
.other_services__banner,
.tehservice__banner {
  height: 375px;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.service__banner_title {
  color: #fff;
  border: 3px solid #fff;
  z-index: 2;
  font-size: 2.5rem;
  padding: 5px 10px;
  max-width: 1000px;
  margin: 0 20px;
  text-align: center;
}
.autodiagnostic__banner::after,
.hodovaya__banner::after,
.tehfluids__banner::after,
.tire__banner::after,
.cooling_system__banner::after,
.rozval__banner::after,
.braksystem__banner::after,
.radiator__banner::after,
.conditioner__banner::after,
.steering__banner::after,
.other_services__banner::after,
.clutch__banner::after,
.electric__banner::after,
.ignition__banner::after,
.svarka__banner::after,
.injection__banner::after,
.replacement_belts__banner::after,
.engine__banner::after,
.tehservice__banner::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.664);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.service__info {
  max-width: 1100px;
  margin-left: 150px;
  font-size: 1rem;
  margin-top: 3rem;
  padding: 0 20px;
}
.service_table li{
  list-style: none !important;
  padding: 0 !important;
  width: 100%;
  border-top: 1px solid #000;
  border-left: 1px solid #000;
  color: red !important;
  border-right: 1px solid #000;
  gap: 0 !important;
  margin-bottom: 70px !important;
}
.services_table_autodiagnostic{
  list-style: none !important;
}
.services_table_autodiagnostic li {
  position: relative;
}
.services_table_autodiagnostic li:nth-child(1) {
  font-size: 18px;
  font-weight: 500;
}
.services_table_autodiagnostic li:nth-child(1)::before {
  content: "";
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 100%;
  background-color: #000;
  left: -14px;
  bottom: 7px;
}
.services_table_autodiagnostic li:nth-child(+n+2)::before {
  content: " ";
  position: absolute;
  width: 5px;
  height: 5px;
  transform: rotate(135deg);
  background-color: #000;
  left: 24px;
  bottom: 7px;
}
.services_table_autodiagnostic li:nth-child(+n+2) {
  padding-left: 40px;
}
.service_table__first {
  background-color: #241d1d !important;
  color: #fff !important;
  height: 40px;
}
.service_table li {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #000;
  height: 40px;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  transition: 0.4s all ease;
}
.under_service__text {
  color: red !important;
  font-size: 14px;
  height: 25px !important;
}
.under_service__text:hover {
  background-color: #fff !important;
}
.warning {
  display: flex;
  align-items: center;
  gap: 10px;
  color: red;
}
.service__li_item span {
  margin-right: 5px;
}
.service__li_item span:nth-child(2){
  font-weight: bold;
}
.service_table li:hover {
  background-color: #54b0df;
}
.service_table li span:nth-child(1) {
  width: 745px;
  padding-left: 20px;
}
.service_table li span:nth-child(2) {
  width: 330px;
  text-align: center;
  position: relative;
  font-size: 15px;
}
.service_table li span:nth-child(1) {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
}
.service_table li span:nth-child(1)::after {
  content: "";
  position: absolute;
  width: 1px;
  background-color: #000;
  right: 0;
  bottom: 0;
  top: 0;
  height: 100%;
}

.autodiagnostic__banner {
  background-image: url("../img/autodiagnostic.jpeg");
}
.ignition__banner {
  background-image: url("../img/engines_imgs.jpg");
}
.svarka__banner {
  background-image: url("../img/svarka_imgs.jpg");
}
.radiator__banner {
  background-image: url("../img/radiator_imgs.jpg");
}
.clutch__banner {
  background-image: url("../img/clutch_imgs.webp");
}
.injection__banner {
  background-image: url("../img/injection_imgs.jpg");
}
.cooling_system__banner {
  background-image: url("../img/cooling_imgs.jpg");
}
.replacement_belts__banner {
  background-image: url("../img/belt_imgs.jpg");
}
.braksystem__banner {
  background-image: url("../img/break_imgs.jpg");
}
.steering__banner {
  background-image: url("../img/steering_imgs.jpg");
}
.tehfluids__banner {
  background-image: url("../img/fluids_imgs.jpg");
}
.engine__banner {
  background-image: url("../img/engine_imgs.jpg");
}
.tehservice__banner {
  background-image: url("../img/tehService.jpeg");
}
.hodovaya__banner {
  background-image: url("../img/hodova.jpg");
}
.other_services__banner {
  background-image: url("../img/other_services.jpg");
}
.electric__banner {
  background-image: url("../img/electric.jpg");
}
.conditioner__banner {
  background-image: url("../img/conditioner_img.jpeg");
}
.tire__banner {
  background-image: url("../img/tire.jpg");
}
.rozval__banner {
  background-image: url("../img/rozval_img.jpeg");
}

/* /////TIRE */

table {
  width: 100%;
  text-align: center;
  border: 1px solid #000;
  border-collapse: collapse;
  margin: 3rem 0;
  background-color: #fff;
}
table tr:nth-child(1) {
  background-color: #241d1d;
  color: #fff;
  font-weight: bold;
}
table tr:nth-child(1):hover {
  background-color: #241d1d;
}
table tr {
  border-bottom: 1px solid #000 !important;
}
table td {
  border-left: 1px solid #000;
  height: 100%;
  padding: 10px;
  margin: 0;
  transition: 0.4s all ease;
}
table tr:hover {
  background-color: #54b0df;
}
.tire_content {
  width: 100%;
  margin: auto;
}

/* //////CONTACTS */

.contacts {
  width: 100%;
  padding: 3rem 0;
}
.contacts__container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  max-width: 1140px;
  margin: auto;
}
.contacts__map {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
}
.contacts__map {
  max-width: 450px;
}
.contacts img:nth-child(1) {
  max-width: 200px;
}
.contacts img {
  max-width: 100%;
}

.contacts__info {
  width: 540px;
}
.contacts__info .service__title {
  font-size: 2.5rem;
  position: relative;
}
.contacts__info .service__title::after {
  content: "";
  position: absolute;
  width: 200px;
  height: 10px;
  background-color: #39b44a;
  bottom: -20px;
  left: 0;
}
.contacts__adress {
  font-size: 1.75rem;
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-weight: bold;
}
.contacts__info p:nth-child(2) span {
  font-weight: 500;
}

.contacts__adress span,
.contacts__adress a {
  font-size: 1rem;
  font-weight: normal;
}
.links__menu_text {
  display: flex;
  flex-direction: row !important;
}
.all_services_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  height: 300px;
  background-image: url("../img/all_services.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  cursor: pointer;
  color: #fff;
}

.all_services_btn::after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  background-color: #0000007e;
  width: 100%;
  height: 100%;
  transition: 0.7s all ease;
}

.all_services {
  font-size: 55px;
  z-index: 3;
}

.all_services_btn:hover::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: 1s all ease;
  background-color: #000000b9;
}

/* /////////////ALL SERVICES */

.allServices {
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  margin: auto;
}


@media (max-width: 1200px) {
  .service__info {
    width: 100% !important;
    margin-left: 0;
  }
  .service_table li {
    height: 100%;
  }
  .service_table li span:nth-child(2)::after {
    width: 0px !important;
  }
  .service_table li span:nth-child(1) {
    position: relative;
    padding: 10px 0 10px 20px;
  }
  .service_table li span:nth-child(1):after {
    content: "";
    position: absolute;
    right: 0px;
    top: 0;
    background-color: #000;
    width: 1px;
    height: 100%;
  }
  .about {
    padding: 0 40px;
  }
  .about__content {
    display: flex;
    justify-content: center;
  }
  .about__left_side {
    display: flex;
    margin: auto;
    flex-direction: column;
  }
  .about__title {
    display: flex;
    max-width: 800px;
  }
  .about__right_side {
    width: 800px;
  }
  .about__right_side img {
    width: 100% !important;
    object-fit: contain;
  }
  .about__right_side img:nth-child(2) {
    height: 400px;
    max-width: 300px !important;
  }
  .contacts {
    padding: 20px;
  }
  .contacts__container {
    justify-content: center;
    gap: 0px;
  }
  .contacts__info {
    max-width: 440px !important;
  }
}

@media (max-width: 1055px) {
  .contacts__container {
    justify-content: center;
    gap: 50px !important;
    text-align: center;
  }
  .contacts__info {
    max-width: 440px !important;
  }
}

@media (max-width: 890px) {
  .fix_kinds .advances__title::after {
    left: 38%;
  }
  .about__right_side img {
    margin-left: 0 !important;
  }
  .tire_content {
    overflow-x: scroll;
  }
}

@media (max-width: 700px) {
  .fix_kinds .advances__title::after {
    left: 34%;
  }

  .diagnostic .advances__title::after {
    left: 35%;
    transition: 1s all ease;
  }
  .under_service__text {
    height: 100% !important;
    padding: 0 20px;
  }
}

@media (max-width: 588px) {
  .contacts iframe {
    width: 100%;
  }
  .contacts__map {
    width: 100%;
  }
}

@media (max-width: 558px) {
  .advances__title::after {
    left: 30%;
    transition: 1s all ease;
  }
  .diagnostic .advances__title::after {
    left: 30%;
  }
}
@media (max-width: 450px) {
  .all_services {
    font-size: 30px;
  }
}

@media (max-width: 430px) {
  .fix_kinds .advances__title::after {
    left: 20%;
  }
  .diagnostic {
    text-align: center;
  }
  .about {
    padding: 0 5px;
  }
  .service__banner_title {
    font-size: 2.2rem;
    padding: 0;
    margin: 0 10px;
  }
}

@media (max-width: 390px) {
  .advances__title::after {
    left: 22%;
  }
  .diagnostic .advances__title::after {
    left: 22%;
  }
  .fix__block::after {
    background-size: contain;
  }
}
