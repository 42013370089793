body,
html {
  margin: 0;
  padding: 0;
  background-color: #fff9d469;
}
* {
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

a {
  text-decoration: none;
}
ul {
  list-style: none;
}

header {
  background-color: #241d1d;
  padding: 12px 0;
  color: #fff;
  display: flex;
  justify-content: center;
}
.header__content {
  display: flex;
  align-items: center;
  max-width: 1200px;
  gap: 40px;
  margin: auto;
  flex-wrap: wrap;
}

.header__img_logo,
.phone_icon,
.mark_icon,
.header__cards,
.arrow_down_icon {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.phone_icon,
.mark_icon {
  display: block;
  width: 24px;
  height: 24px;
}

.header__img_logo {
  width: 285px;
  height: 80px;
}
.header__img_logo_span {
  background-image: url("../img/logo4.png");
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  display: block;
}

.header__phone_content,
.header__map_adress {
  width: 285px;
}

.header__phone_content {
  display: flex;
  gap: 15px;
  align-items: center;
}
.header__phone {
  color: #fff;
  font-size: 1.62rem;
}
.phone_icon {
  background-image: url("../img/phone.svg");
}

.header__map_adress {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.mark_icon {
  background-image: url("../img/mark.svg");
}

.header__schedule_content {
  display: flex;
  flex-direction: column;
  text-align: right;
  width: 140px;
  padding-bottom: 5px;
}
.header__schedule {
  display: flex;
  flex-direction: column;
  margin: 0 0 5px 0;
}
.header__cards {
  background-image: url("../img/payments.svg");
  width: 120px;
  height: 30px;
  display: block;
  margin-left: 20px;
}

/* ////LINKS BAR STYLES */

nav {
  background-color: rgb(51, 51, 51);
  width: 100%;
}
.nav__content {
  display: flex;
  align-items: center;
  max-width: 1100px;
  margin: auto;
  height: 50px;
  justify-content: center;
}
.nav__content a {
  color: #fff;
  width: 207px;
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.4s all ease;
}
.nav__content a:hover{
  background-color: #000;
  cursor: pointer;
}
.burger__block {
  transition: 0.4s all ease;
  height: 100%;
  position: relative;
}
.burger__block .nav__menu:hover {
  background-color: #000;
}
.burger__content {
  background-color: #000;
}
.arrow_down_icon {
  width: 17px;
  height: 17px;
  display: block;
  background-image: url("../img/down_arrow.svg");
  margin-left: 4px;
}
.nav__menu {
  position: relative;
}
.active__menu {
  background-color: #fff;
  position: absolute;
  left: 0;
  top: 34px;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  text-align: left !important;
  padding: 20px 0;
  width: 250px;
  max-height: 425px;
  overflow-y: scroll;
}
.active__menu a {
  width: 100%;
  text-align: left;
  display: flex;
  justify-content: flex-start;
  padding: 0 20px;
}
.active__menu a li {
  width: 100%;
  color: #000000b7;
}
.active__menu li:hover {
  color: green;
}
.active__menu a:hover {
  background-color: transparent !important;
}
.link_view {
  padding-left: 150px;
  font-size: 0.95rem;
}

/* //////MOBILE MENU */
.active__mobile_menu {
  display: none;
}
.unActive {
  display: none;
}

.link_view {
  color: blue;
}
.links__menu_text {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media (max-width: 1135px) {
  .header__content {
    gap: 10px;
  }
}
@media (max-width: 1039px) {
  .header__content {
    gap: 0px;
  }
  .header__phone_content,
  .header__map_adress {
    width: 230px;
  }
}

@media (max-width: 889px) {
  .header__content {
    justify-content: center;
    gap: 10px;
  }
  nav {
    position: relative;
    height: 43px;
  }
  .active__mobile_menu {
    width: 30px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    cursor: pointer;
    position: absolute;
    right: 20px;
    z-index: 3;
  }
  .active__mobile_menu span:nth-child(1) {
    border-radius: 5px 5px 0px 0px;
  }
  .active__mobile_menu span:nth-child(3) {
    border-radius: 0px 0px 5px 5px;
  }
  .mobile_burger {
    width: 30px;
    height: 4px;
    background-color: #fff;
  }
  .nav__content {
    display: none;
  }
  nav {
    padding: 10px 0;
  }
  .nav_burger_content {
    position: fixed;
    background-color: #241d1d;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    z-index: 4;
    top: 0;
    width: 250px;
    text-align: center;
    padding-top: 50px;
    animation: menu;
    animation-duration: 1s;
  }
  .nav_burger_content a {
    color: #fff;
    position: relative;
    font-size: 1.75rem;
    padding: 20px 0;
  }
  .nav_burger_content a:hover {
    background-color: #000;
  }
  .nav_burger_content a::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: #fff;
    left: 0;
    bottom: -0px;
  }
  .nav__menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .dark_bg_on_open {
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.712);
    z-index: 2;
    top: 0;
  }
  .active__menu {
    background-color: #241d1d;
    left: 250px;
    top: -19px;
    border-left: 2px solid #fff;
    padding-left: 20px;
    overflow-y: auto;
    height: 600px;
  }
  .active__menu a {
    color: #fff;
    font-size: 1rem;
  }
  .active__menu a li {
    color: gray;
  }
  .active__menu a li:hover {
    color: #fff;
  }
  .active__menu a::after {
    content: "";
    display: none;
  }
  .close_modal {
    display: flex;
    flex-direction: column;
    width: 40px;
    height: 40px;
    margin: auto;
    position: relative;
    border: 3px solid #fff;
    border-radius: 100%;
    cursor: pointer;
  }
  .close_modal span {
    width: 30px;
    height: 3px;
    background-color: #fff;
    position: absolute;
    bottom: 15px;
    left: 2px;
  }
  .close_modal span:nth-child(1) {
    transform: rotate(135deg);
  }
  .close_modal span:nth-child(2) {
    transform: rotate(45deg);
  }
  .burger__block {
    height: 70px !important;
  }
}

@media (max-width: 590px) {
  .active__menu {
    border-bottom: 3px solid #fff;
    border-left: 0;
    padding: 0;
    left: 0;
    top: 60px;
    height: 480px;
  }
  .burger__block {
    display: flex;
    flex-direction: column;
    height: 100%;

  }
  .active__menu a {
    text-align: center;
    padding: 10px 0;
  }
  .nav__menu {
    display: flex;
    flex-direction: column;
  }
  .nav_burger_content {
    padding-top: 30px;
  }
  .link_view {
    padding: 0 0 0px 20px;
  }
  .header__schedule_content {
    text-align: center;
  }
}

@media (max-width: 460px) {
  .header__map_adress {
    max-width: 150px;
  }
}


@keyframes menu {
  0% {
    left: -280px;
  }
  100% {
    left: 0;
  }
}