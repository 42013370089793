.swiperBottom {
    max-width: 1200px;
    height: 150px !important;
    overflow: hidden !important;
  }
  
 .swiperBottom .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 200px !important;
    height: 150px !important;
  }
  
 .swiperBottom .swiper-slide img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
  