footer {
  background-color: #241d1d;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 48px 0;
}
.footer__container {
  max-width: 1200px;
  margin: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 114px;
  justify-content: center;
}
.links_block {
  display: flex;
  flex-direction: column;
  text-align: left;
  color: #fff;
}
.menu__block p, li{
    font-weight: 400;
    color: gray;
}
.menu__block p {
    margin: 0 0 10px 0;
}
.links__title_first {
    font-size: 20px;
    font-weight: 400;
    position: relative;
    margin-bottom: 30px;
}
.links__title_first::after {
    content: " ";
    background-color: #39b44a;
    width: 63px;
    height: 3px;
    position: absolute;
    bottom: -15px;
    left: 0;
  }
.links__title {
  font-size: 20px;
  font-weight: 400;
  position: relative;
  margin-bottom: 10px;
}
.links__title::after {
  content: " ";
  background-color: #39b44a;
  width: 63px;
  height: 3px;
  position: absolute;
  bottom: -10px;
  left: 0;
}
.viber_icon {
  background-image: url("../img/viber-icon.svg");
}
.telegram_icon {
  background-image: url("../img/telegram-icon.svg");
}
.watsup_icon {
  background-image: url("../img/whatsapp-icon.svg");
}
.contacts__block {
  display: flex;
  gap: 5px;
}
.viber_icon,
.telegram_icon,
.watsup_icon {
  display: block;
  width: 45px;
  height: 45px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

ul {
    padding: 0;
}

.menu__block {
    display: flex;
    flex-direction: column;
}

.menu__block a{
    color: gray;
}
.menu__block a li:hover{
    color: #fff;
}
.footer_tel a, .footer_email a{
  color: #39b44a;
}
.first_links_block {
  display: flex;
  gap: 100px;
}

@media (max-width: 990px) {
  .footer__container {
    gap: 50px !important;
    padding: 0 20px;
  }
  .first_links_block:nth-child(1) {
    gap: 205px !important;
  }
  .first_links_block {
    width: 500px;
  }
}
@media (max-width: 590px) {
  .footer__container {
    gap: 50px !important;
    justify-content: flex-start;
  }
  .first_links_block {
    width: 100%;
    flex-wrap: wrap;
    margin: auto;
    gap: 0;
    justify-content: space-between;

  }
  .first_links_block:nth-child(1) {
    gap: 0px !important;
    max-width: 450px;
  }
  .first_links_block:nth-child(2) {
    gap: 10px !important;
    margin: auto;
  }

}
@media (max-width: 450px) {
  .first_links_block:nth-child(2) {
    gap: 10px !important;
  }
}
@media (max-width: 480px) {
  .footer__container .first_links_block:nth-child(2) .links_block:nth-child(1) {
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    gap: 5px;
  }
}